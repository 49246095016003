<template>
	<div>
		<!-- 公共头部 -->
		<Header title=""></Header>
		
		<div class="section1">
			<div class="item">选择校区<div id="trigger1" class="hide-input">选择校区</div></div>
			<div class="item" v-show="schoolSubject.length">选择工种<div id="trigger4" class="hide-input">选择工种</div></div>
		</div>
		<div class="section2">
			<ul class="list">
				<li class="item" v-for="(item,index) in list" :key="index">
					<div class="content">
						<h2 class="title">{{item.name}}</h2>
						<div class="desc">{{item.size}}</div>
					</div>
					<a :href="item.path" download class="pic">
						<img class="img" src="@/assets/images/icon18.png" />
					</a>
				</li>
			</ul>
			<wd-pagination v-model="page" :total="total"></wd-pagination>
		</div>
		
		<!-- 公共底部 -->
		<Footer></Footer>
	</div>
</template>

<script>
	var mobileSelect4;
	import MobileSelect from 'mobile-select';
	import Header from '@/components/Public/Header.vue';
	import Footer from '@/components/Public/Footer.vue';
	export default {
		components: {
			Header,
			Footer
		},
		data() {
			return {
				page: 1,
				limit: 10,
				total: 0,
				list: [],
				schoolList: [],
				school_id: '',
				schoolSubject: [],
				yi_id: '',
				er_id: '',
				san_id: '',
				keywords: '',
				newStatus: false
			}
		},
		watch: {
			$route(to,form) {
				if((to.path == form.path) && (this.$route.path == '/download')){
					if(this.page == 1){
						this.getList();
					} else {
						this.page = 1;
					}
				}
			},
			page() {
				if(this.$route.path == '/download'){
					this.getList();
				}
			},
			er_id() {
				if(this.$route.path == '/download'){
					this.getList();
				}
			}
		},
		created() {
			this.getList();
			this.getSchoolList();
		},
		methods: {
			// 获取下载列表
			async getList() {
				var keywords = this.keywords;
				var school_id = this.school_id;
				var uid = this.$store.state.userInfo.id || '';
				var yi_id = this.yi_id;
				var er_id = this.er_id;
				var san_id = this.san_id;
				var page = this.page;
				var limit = this.limit;
				var data = {
					keywords, school_id, page, limit, uid, yi_id, er_id, san_id
				}
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/other/materialDownload', { params: data });
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('资料下载列表：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					this.total = Number(res.data.count);
					var list = res.data.lists;
					for(var i = 0; i < list.length; i++) {
						if(list[i]['path'] && list[i]['path'].substring(0,4) != 'http'){
							list[i]['path'] = this.$store.state.http + list[i]['path'];
						}
					}
					this.list = list;
				} else {
					this.list = [];
				}
			},
			// 获取校区下的工种列表
			async getSchoolSubject() {
				var school_id = this.school_id;
				this.yi_id = "";
				this.er_id = "";
				this.san_id = "";
				if(school_id){
					var data = {
						school_id
					}
					this.$toast.loading('加载中...');
					const { data:res } = await this.$http.get('/school/schoolSubject', { params: data });
					this.$toast.close();
					if(this.$store.state.beta){
						console.log('个人报名 培训项目：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
					}
					var code = res.code;
					if(code == 1) {
						this.schoolSubject = res.data;
						if(!this.newStatus){
							this.newStatus = true;
							var that = this;
							mobileSelect4 = new MobileSelect({
								trigger: "#trigger4",
								title: "工种选择",
								wheels: [
									{
										data: that.schoolSubject,
									}
								],
								keyMap: {
									id: 'id',
									value: 'title',
									childs :'lists'
								},
								callback(indexArr, value){
									if(value[0]) {
										that.yi_id = value[0].id;
									} else {
										that.yi_id = "";
									}
									if(value[1]) {
										that.er_id = value[1].id;
									} else {
										that.er_id = "";
									}
									if(value[2]) {
										that.san_id = value[2].id;
									} else {
										that.san_id = "";
									}
								}
							});
						} else {
							mobileSelect4.updateWheels(this.schoolSubject);
						}
					} else {
						this.schoolSubject = [];
					}
				} else {
					this.schoolSubject = [];
				}
			},
			// 获取校区列表
			async getSchoolList() {
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/school/school');
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('资料下载 校区：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					var schoolList = res.data;
					this.schoolList = schoolList;
					var that = this;
					var mobileSelect1 = new MobileSelect({
						trigger: "#trigger1",
						title: "选择校区",
						wheels: [
							{
								data: schoolList,
							}
						],
						keyMap: {
							id: 'id',
							value: 'title',
							childs :'lists'
						},
						callback(indexArr, value){
							if(value[0]) {
								that.school_id = value[0].id;
							} else {
								that.school_id = "";
							}
							that.getSchoolSubject();
						}
					});
				} else {
					this.schoolList = [];
				}
			},
		},
	};
</script>

<style scoped>
	.section1{
		background: #ffffff;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
	}
	.section1 .item{
		font-size: 0.28rem;
		color: #333333;
		display: inline-block;
		vertical-align: top;
		height: 0.8rem;
		line-height: 0.8rem;
		padding: 0 0.3rem;
		background: url(../../assets/images/icon17.png) no-repeat right 0.05rem center;
		background-size: 0.14rem 0.08rem;
		position: relative;
	}
	.section1 .item .hide-input{
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 666;
		top: 0;
		left: 0;
		font-size: 0;
	}
	.section2{
		margin-top: 0.3rem;
		margin-bottom: 0.8rem;
	}
	.section2 .list{
		background: #ffffff;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
		margin-bottom: 0.8rem;
	}
	.section2 .list .item{
		padding: 0.2rem 0.3rem;
		border-top: 0.02rem solid #e1e1e1;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.section2 .list .item:first-child{
		border-top: none;
	}
	.section2 .list .item .content{
		max-width: 6.2rem;
		padding-left: 0.8rem;
		background: url(../../assets/images/icon19.png) no-repeat left top 0.05rem;
		background-size: 0.6rem 0.57rem;
	}
	.section2 .list .item .content .title{
		font-size: 0.26rem;
		color: #333333;
		margin-bottom: 0.1rem;
	}
	.section2 .list .item .content .desc{
		font-size: 0.24rem;
		color: #969897;
	}
	.section2 .list .item .pic{
		width: 0.4rem;
		height: 0.4rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.section2 .list .item .pic .img{
		max-height: 100%;
	}
</style>